import React from 'react'
import { Link, graphql } from 'gatsby'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
// import Hero from '../components/Hero'
import Breadcrumbs from '../components/Breadcrumbs'


const NewfAbout = ({ data, pageContext, location }) => {
  const newfabout = data.strapiNewfAbout
  const { previous, next } = pageContext

  return (
    <Layout pageData={data.strapiNewfoundlander}>
      <Seo title={newfabout.title} description={newfabout.content} />
      <Breadcrumbs
        firstName="De Newfoundlander"
        firstSlug="newfoundlander"
        secondName={newfabout.title}
      />

      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column">
              <h1 className="title">{newfabout.title}</h1>
              
              <ReactMarkdown
                className="line-break"
                children={newfabout.content} 
                remarkPlugins={[remarkGfm]}
              />
            </div>
          </div>
        </div>
      </section>

      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        {previous && (
          <Link to={`/newfoundlander/${previous.slug}`} className="pagination-previous">
            {previous.title}
          </Link>
        )}
        {next && (
          <Link to={`/newfoundlander/${next.slug}`} className="pagination-next">
            {next.title}
          </Link>
        )}
      </nav>
    </Layout>
  )
}

export default NewfAbout


export const query = graphql `
  query NewfAboutQuery($id: String!) {
    strapiNewfoundlander {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    strapiNewfAbout(id: {eq: $id}) {
      strapiId
      title
      slug
      content
      published_at
    }
  }
`
